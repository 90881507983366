import React from 'react';
import Layout from "../../components/Layout";
import NavFour from "../../components/NavFour";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
// import Gallery from "../components/Gallery";
// import UserPage from '../components/UserPage';
import CheckManuscriptStatus from "../../components/CheckManuscriptStatus";
import EvidenceUpload from '../../components/EvidenceUpload';

const Grants = () => {
    return (
        <Layout pageTitle="Grants | LUTH">
            <NavFour />
            <PageHeader title="" />
            <EvidenceUpload />
            {/* <Gallery /> */}
            <Footer />
        </Layout>
    );
};

export default Grants;
