import React, { useState } from 'react';
import { firestore, storage } from './firebase';

const EvidenceUpload = () => {
  const [id, setId] = useState(''); // The Firestore ID (e.g., 'LUTHGAM-xxxxx')
  const [email, setEmail] = useState('');
  const [firebaseDocId, setFirebaseDocId] = useState(''); // Firebase document ID
  const [
    evidenceOfDissertationProposal,
    setEvidenceOfDissertationProposal,
  ] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  // Handle authentication
  const handleAuth = async () => {
    try {
      const userRef = await firestore
        .collection('SubmittedManuscripts')
        .where('id', '==', id) // Matching Firestore 'id' field (e.g., 'LUTHGAM-xxxxx')
        .where('email', '==', email)
        .get();

      if (userRef.empty) {
        setUploadMessage('Invalid ID or Email');
        setShowPopup(true); // Show popup with error message
      } else {
        const doc = userRef.docs[0]; // Assuming there is only one match
        setFirebaseDocId(doc.id); // Save the Firebase document ID
        setIsAuthenticated(true);
        setUploadMessage('Authenticated');
        setShowPopup(true); // Show popup with success message
      }
    } catch (error) {
      setUploadMessage('Error during authentication');
      console.error(error);
      setShowPopup(true); // Show popup with error message
    }
  };

  // Handle file submission
  const handleFileUpload = async (e) => {
    e.preventDefault();

    if (!evidenceOfDissertationProposal) {
      setUploadMessage('Please upload a file');
      setShowPopup(true); // Show popup for missing file
      return;
    }

    try {
      // Upload file to Firebase Storage
      const fileRef = storage
        .ref()
        .child(`manuscripts/${evidenceOfDissertationProposal.name}`);
      await fileRef.put(evidenceOfDissertationProposal);
      const fileURL = await fileRef.getDownloadURL();

      // Store file URL in Firestore using the Firebase document ID
      await firestore.collection('SubmittedManuscripts').doc(firebaseDocId).set(
        {
          evidenceOfDissertationProposal: fileURL,
        },
        { merge: true }
      );

      setUploadMessage('File uploaded successfully!');
      setShowPopup(true); // Show popup with success message
      // Refresh the page after successful upload
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      setUploadMessage('Error uploading file');
      console.error(error);
      setShowPopup(true); // Show popup with error message
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Close the popup
  };

  return (
    <div
      style={{
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        maxWidth: '600px',
        margin: 'auto',
      }}
    >
      <h2>Manuscripts Upload</h2>

      {/* Authentication Form */}
      <div style={{ marginBottom: '20px' }}>
        <label
          style={{ display: 'block', fontSize: '16px', marginBottom: '8px' }}
        >
          ID:
        </label>
        <input
          style={{
            width: '100%',
            padding: '8px',
            fontSize: '14px',
            marginBottom: '15px',
          }}
          type='text'
          value={id}
          onChange={(e) => setId(e.target.value)}
          required
        />
        <label
          style={{ display: 'block', fontSize: '16px', marginBottom: '8px' }}
        >
          Email:
        </label>
        <input
          style={{
            width: '100%',
            padding: '8px',
            fontSize: '14px',
            marginBottom: '15px',
          }}
          type='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            backgroundColor: '#007BFF',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={handleAuth}
        >
          Authenticate
        </button>
      </div>

      {/* File Upload Form (only shown after authentication) */}
      {isAuthenticated && (
        <form onSubmit={handleFileUpload}>
          <div style={{ marginBottom: '15px' }}>
            <label
              style={{
                display: 'block',
                fontSize: '16px',
                marginBottom: '8px',
              }}
            >
              Upload Dissertation Proposal Evidence:
            </label>
            <input
              style={{ width: '100%', padding: '8px', fontSize: '14px' }}
              type='file'
              onChange={(e) =>
                setEvidenceOfDissertationProposal(e.target.files[0])
              }
              required
            />
          </div>
          <button
            style={{
              padding: '10px 20px',
              fontSize: '16px',
              backgroundColor: '#28A745',
              color: '#fff',
              border: 'none',
              cursor: 'pointer',
            }}
            type='submit'
          >
            Submit File
          </button>
        </form>
      )}

      {/* Popup for Success or Error Messages */}
      {showPopup && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex: 1000,
          }}
        >
          <p>{uploadMessage}</p>
          <button
            style={{
              padding: '5px 10px',
              marginTop: '10px',
              backgroundColor: '#007BFF',
              color: '#fff',
              border: 'none',
              cursor: 'pointer',
            }}
            onClick={handleClosePopup}
          >
            Close
          </button>
        </div>
      )}

      {/* Overlay (for dimming the background when popup is shown) */}
      {showPopup && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999,
          }}
        ></div>
      )}
    </div>
  );
};

export default EvidenceUpload;
